import historyImg from "../assets/02_about/02_history_bg.jpg"
import CeoMini from "./CeoMini";
import { useMediaQuery } from 'react-responsive';

export default function History() {
  const isTablet = useMediaQuery({ maxWidth: 1349 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      <div className=" mx-auto py-2 bg-cover bg-no-repeat " style={{ backgroundImage: `url(${historyImg})` }}>
        <div className={`${isMobile ? "w-4/5" : "w-3/5"} mx-auto pb-10`}>
          <div className={`text-primary-purple ${isMobile ? " text-xs" : ""} font-extrabold mb-2 mt-5`}>History</div>
          {isMobile ?
            <div className="text-white text-lg-title-desktop font-semibold">
              <div className={`text-lg-title-mobile  text-white font-semibold`}> 연혁 </div>
              <div className="flex">
                <div className="ml-8 h-[64vh] w-[4px] mt-7 relative" style={{ background: 'linear-gradient(transparent, #909090, transparent)' }}>
                  <div id="dot1" className="absolute top-[13%] w-[10px] h-[10px] rounded-full border-[#8264E1] border-[2px] " style={{ transform: 'translateX(-40%)' }} />
                  <div id="dot2" className="absolute top-[40%] w-[10px] h-[10px] rounded-full border-[#8264E1] border-[2px] " style={{ transform: 'translateX(-40%)' }} />
                  <div id="dot3" className="absolute top-[85%] w-[10px] h-[10px] rounded-full border-[#8264E1] border-[2px] " style={{ transform: 'translateX(-40%)' }} />
                </div>
                <div className="relative w-[75%] ml-4">
                  <div id="history-box1" className="flex absolute top-[11%] text-white">
                    <div className={`mt-[25px] text-desc-tablet text-white font-semibold`}>2022 </div>
                    <ul className={`text-white text-desc-tablet  font-normal`} style={{ transform: 'translateX(20%)' }}>
                      <li className="pt-4 pb-1">한국철도기술연구원 프로젝트 </li>
                      <li className="py-1">한국수력원자력 중앙연구원 프로젝트</li>
                    </ul>
                  </div>
                  <div id="history-box2" className="flex absolute top-[36%]  text-white">
                    <div className={`mt-[25px] text-desc-tablet  text-white font-semibold`}>2023 </div>
                    <ul className={`text-white  text-desc-tablet font-normal`} style={{ transform: 'translateX(20%)' }}>
                      <li className="pt-4 pb-1" > 성균관대 캠퍼스타운 킹고인 입주경진대회 선정</li>
                      <li className="py-1" > 한국철도기술연구원 프로젝트</li>
                      <li className="py-1" > 시청 프로젝트</li>
                      <li className="py-1" > 청년창업사관학교 13기 선정</li>
                      <li className="py-1" > 현대자동차 정몽구재단 H-온드림 11기 선정 </li>
                    </ul>
                  </div>
                  <div id="history-box2" className="absolute top-[79%]  w-full text-white">
                    <div className={`mt-[25px] text-desc-tablet text-white font-semibold`}>현재
                    </div>
                  </div>
                </div>
              </div>
            </div> :
            <div className="flex text-white text-lg-title-desktop font-semibold">
              <div className={`text-lg-title-desktop text-white font-semibold`}> 연혁 </div>
              <div className="w-[52vw]">
                <div className="ml-8 h-[2px] w-[55vw] mt-7 relative" style={{ background: 'linear-gradient(to right, transparent, #909090, transparent)' }}>
                  <div id="dot1" className="absolute -bottom-[5px] left-[20%] w-[10px] h-[10px] rounded-full border-[#8264E1] border-[2px] " style={{ transform: 'translateX(-50%)' }} />
                  <div id="dot2" className="absolute -bottom-[5px] left-[52%] w-[10px] h-[10px] rounded-full border-[#8264E1] border-[2px] " style={{ transform: 'translateX(-0%)' }} />
                  <div id="dot3" className="absolute -bottom-[5px] left-[86%] w-[10px] h-[10px] rounded-full border-[#8264E1] border-[2px] " style={{ transform: 'translateX(-50%)' }} />
                </div>
                <div className="relative flex mx-auto w-[66vw] h-[300px]">
                  <div id="history-box1" className="absolute left-[20%] text-white" style={{ transform: 'translateX(-48%)' }}>
                    <div className="text-xl font-semibold mt-[25px] text-center w-[16vw]">2022
                    </div>
                    <ul>
                      <li className=" text-base w-[15vw] my-[5px] py-[3px]" style={{ transform: 'translateX(35%)' }}>한국철도기술연구원 프로젝트 </li>
                      <li className=" text-base w-[15vw] my-[5px] py-[3px]" style={{ transform: 'translateX(35%)' }}>한국수력원자력 중앙연구원 프로젝트</li>
                    </ul>
                  </div>
                  <div id="history-box2" className="absolute left-[47%] text-white" style={{ transform: 'translateX(-47%)' }}>
                    <div className="text-xl font-semibold mt-[25px] text-center w-[16vw]">2023
                    </div>
                    <ul>
                      <li className=" text-base w-[15vw] my-[5px] py-[3px]" style={{ transform: 'translateX(35%)' }}>성균관대 캠퍼스타운 킹고인 입주경진대회 선정</li>
                      <li className=" text-base w-[15vw] my-[5px] py-[3px]" style={{ transform: 'translateX(35%)' }}>한국철도기술연구원 프로젝트</li>
                      <li className=" text-base w-[15vw] my-[5px] py-[3px]" style={{ transform: 'translateX(35%)' }}>시청 프로젝트</li>
                      <li className=" text-base w-[15vw] my-[5px] py-[3px]" style={{ transform: 'translateX(35%)' }}>청년창업사관학교 13기 선정</li>
                      <li className=" text-base w-[15vw] my-[5px] py-[3px]" style={{ transform: 'translateX(35%)' }}>현대자동차 정몽구재단 H-온드림 11기 선정 </li>
                    </ul>
                  </div>
                  <div id="history-box2" className="absolute left-[75%] text-white" style={{ transform: 'translateX(-50%)' }}>
                    <div className="text-xl font-semibold mt-[25px] text-center w-[16vw]">현재
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          <div className={`text-primary-purple ${isMobile ? " text-xs" : ""} font-extrabold mb-2 mt-24`}>CEO</div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop flex justify-between "}  text-white font-semibold `}>이종민 <CeoMini /></div>
        </div>
      </div>
    </>
  )
}