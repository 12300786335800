import cvImg from "../assets/03_solution/cv/01_cv_bg.jpg"
import cvImgMobile from "../assets/03_solution/cv/01_cv_bg_mobile.png"
import { ReactComponent as DocIcon } from "../assets/03_solution/cv/02_img_icon.svg"
import doc1 from "../assets/03_solution/cv/02_img1.jpg"
import { ReactComponent as EntityIcon } from "../assets/03_solution/cv/03_entity_icon.svg"
import entity1 from "../assets/03_solution/cv/03_entity1.jpg"
import entity2 from "../assets/03_solution/cv/03_entity2.jpg"
import { ReactComponent as SimIcon } from "../assets/03_solution/cv/04_sim_icon.svg"
import sim1 from "../assets/03_solution/cv/04_sim1.jpg"
import sim2 from "../assets/03_solution/cv/04_sim2.jpg"
import { useMediaQuery } from 'react-responsive';

export default function Cv() {
  const isTablet = useMediaQuery({ maxWidth: 1349 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      <div className={`w-screen ${isMobile ? "h-[320px]" : " h-[400px]"} mx-auto bg-no-repeat bg-cover bg-center py-14`} style={{ backgroundImage: `url(${isTablet ? cvImgMobile : cvImg})` }}>
        <div className={`${isMobile ? "w-4/5" : "w-3/5"} mx-auto`}>
          <div className={`text-primary-purple ${isMobile ? " text-xs" : ""} font-extrabold mb-2 mt-5`}>CV</div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"}  text-white font-semibold`}>컴퓨터 비전</div>
          <div className={`text-white ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
            <p>Computer Vision, CV </p>
            <p>컴퓨터가 이미지, 비디오 등과 같은</p>
            <p>시각적 입력 인식하여 의미있는 정보를</p>
            <p>추출하고 이를 활용하는 기계 학습 기술</p>
          </div>
        </div>
      </div >
      <div>
        <div id="part1" className={`${isMobile ? "pt-20 pb-12" : "py-20"}`}>
          <div className="flex justify-center"> <DocIcon height={isMobile ? "40px" : "60px"} /> </div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3 text-center mt-2`}>
            이미지분류
          </div>
          <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"} pb-12`}>
            <p> 주어진 이미지를 특정 범주, 카테고리로 분류</p>
          </div>
          <div id="box-wrap1" className={`flex mb-16 justify-center items-center gap-8 ${isTablet ? " flex-col" : ""}`}>
            <div className={`${isTablet ? isMobile ? "w-5/6 h-[380px] px-3" : "w-2/3 h-1/3 px-5" : "w-1/3 h-1/3 px-3"} py-7 flex flex-col justify-center  rounded-lg`} >
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                활용예시</div>
              <div id="subtitle1-1" className={`text-center font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                동물분류</div>
              <div className={`flex justify-center rounded-lg my-4`}>
                <img src={doc1} style={{ borderRadius: "10px" }} />
              </div>
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                동물 사진을 분석하고, 해당 동물이 무엇인지 식별하는 기술</div>
            </div>
          </div>
        </div>
        <div id="part2" className={`${isMobile ? "pt-20 pb-12" : "py-20"}`}>
          <div className="flex justify-center"> <EntityIcon height={isMobile ? "40px" : "60px"} /> </div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3 text-center mt-2`}>
            개체 인식
          </div>
          <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"} pb-12`}>
            <p>이미지나 비디오 등과 같은 시각 데이터에서 </p>
            <p>특정 객체의 위치를 식별하고 카테고리로 분류 </p>
          </div>
          <div id="box-wrap2" className={`flex mb-16 justify-center items-center gap-8 ${isTablet ? " flex-col" : ""}`}>
            <div className={`${isTablet ? isMobile ? "w-5/6 h-[380px] px-3" : "w-2/3 h-1/3 px-5" : "w-1/3 h-1/3 px-3"} py-7 flex flex-col justify-center  rounded-lg`} >
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                활용예시</div>
              <div id="subtitle2-1" className={`text-center font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                화재 발생 탐지</div>
              <div className={`flex justify-center rounded-lg my-4`}>
                <img src={entity1} style={{ borderRadius: "10px" }} />
              </div>
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                이미지 혹은 영상 내의 장소에서 발생한 화재 장소를 특정해서 시각화하는 시스템</div>
            </div>
            <div className={`${isTablet ? isMobile ? "w-5/6 h-[380px] px-3" : "w-2/3 h-1/3 px-5" : "w-1/3 h-1/3 px-3"} py-7 flex flex-col justify-center  rounded-lg`} >
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                활용예시</div>
              <div id="subtitle2-2" className={`text-center font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                보안 감시 시스템</div>
              <div className={`flex justify-center rounded-lg my-4`}>
                <img src={entity2} style={{ borderRadius: "10px" }} />
              </div>
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                이미지 혹은 영상 내의 장소에서 침입자를 탐지하는 시스템</div>
            </div>
          </div>
        </div>
        <div id="part3" className={`${isMobile ? "pt-20 pb-12" : "py-20"}`}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SimIcon height={isMobile ? "40px" : "60px"} />
          </div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3 text-center mt-2`}>
            이미지 유사도
          </div>
          <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"} pb-12`}>
            <p> 두 개 이상의 이미지간의 유사성을 측정. </p>
            <p> 이미지 간의 관련성을 분석하여 활용.</p>
            <p> 추천 시스템 등 다양한 분야에서 응용 가능 </p>
          </div>
          <div id="box-wrap3" className={`flex mb-16 justify-center items-center gap-8 ${isTablet ? " flex-col" : ""}`}>
            <div className={`${isTablet ? isMobile ? "w-5/6 h-[380px] px-3" : "w-2/3 h-1/3 px-5" : "w-1/3 h-1/3 px-3"} py-7 flex flex-col justify-center  rounded-lg`} >
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                활용예시</div>
              <div id="subtitle3-1" className={`text-center font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                닮은 연예인 찾기</div>
              <div className={`flex justify-center rounded-lg my-4`}>
                <img src={sim1} style={{ borderRadius: "10px" }} />
              </div>
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                사용자가 입력한 이미지와 유사한 연예인 사진을 찾는 시스템</div>
            </div>
            <div className={`${isTablet ? isMobile ? "w-5/6 h-[380px] px-3" : "w-2/3 h-1/3 px-5" : "w-1/3 h-1/3 px-3"} py-7 flex flex-col justify-center  rounded-lg`} >
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                활용예시</div>
              <div id="subtitle3-2" className={`text-center font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                유사 상표 찾기</div>
              <div className={`flex justify-center rounded-lg my-4`}>
                <img src={sim2} style={{ borderRadius: "10px" }} />
              </div>
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                사용자가 입력한 이미지와 유사한 상표를 찾는 시스템</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}