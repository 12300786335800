import { useEffect, useState } from "react";
import { ReactComponent as SvgHome } from "../assets/00_logo/neuroflow_logo_W.svg"
import { FaRegCopyright } from "react-icons/fa6";
// import Modal from "../components/Modal";
import { useNavigate } from "react-router-dom";
import { sendMail } from "../api/mail";
import './contact.css';
import { useMediaQuery } from 'react-responsive';

export default function Contact() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(''); // aisol, techhelp, edu, etc
  const [projDesc, setProjDesc] = useState("")
  const [name, setName] = useState("")
  const [compName, setCompName] = useState("")
  const [num, setNum] = useState("")
  const [pow, setPow] = useState("")
  const [email, setEmail] = useState("")
  const [agree, setAgree] = useState(0)
  const [selectedFile, setSelectedFile] = useState(null);

  const isTablet = useMediaQuery({ maxWidth: 1349 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const refreshAll = () => {
    setProjDesc("")
    setName("")
    setCompName("")
    setNum("")
    setPow("")
    setEmail("")
    setAgree(0)
  }

  return (
    <>
      <div className="w-screen mt-[100px]">
        <div className={`${isMobile ? "w-4/5 py-10" : "w-3/5 py-20"} gap-2 mx-auto  mt-10`}>
          <div className={`text-primary-purple mb-4 font-extrabold ${isMobile ? "text-main-banner-mobile" : "text-main-banner-desktop"}`}>
            Contact</div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold`}>
            <p>비즈니스 성공을 위한 필수 솔루션,</p>
            <p>뉴로플로우와 함께 하세요</p>
            {/* 간단한 문의를 통해 검증된 인공지능 전문가의 답변을 받아보세요. */}
          </div>
          <div className={`text-[#757575] ${isMobile ? "text-desc-tablet" : "text-desc-desktop"} font-normal`}>
            <p> 경기 성남시 분당구 성남대로331번길 8, 킨스타워 19층 </p>
            <p> contact@neuroflow.co.kr </p>
            <p> 09:00~18:00 </p>
          </div>
          <div className={`flex mt-10 mb-16 h-[5px] bg-black opacity-5 rounded-md`} />
          <div id="hang1" className={`${isMobile ? "my-12" : "my-20"} flex flex-col gap-4`} >
            <div className={`font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
              1.의뢰 항목</div>
            <div className="grid grid-cols-2 gap-4">
              <div className={`${activeTab === "aisol" ? "bg-zinc-200 font-semibold" : ""} justify-center items-center border border-zinc-300 rounded-md flex h-16 cursor-pointer`} onClick={() => setActiveTab("aisol")}>AI Solution</div>
              <div className={`${activeTab === "techhelp" ? "bg-zinc-200 font-semibold" : ""} justify-center items-center border border-zinc-300 rounded-md flex h-16 cursor-pointer`} onClick={() => setActiveTab("techhelp")}>기술자문</div>
              <div className={`${activeTab === "edu" ? "bg-zinc-200 font-semibold" : ""} justify-center items-center border border-zinc-300 rounded-md flex h-16 cursor-pointer`} onClick={() => setActiveTab("edu")}>교육</div>
              <div className={`${activeTab === "etc" ? "bg-zinc-200 font-semibold" : ""} justify-center items-center border border-zinc-300 rounded-md flex h-16 cursor-pointer`} onClick={() => setActiveTab("etc")}>기타</div>
            </div>
          </div>
          <div id="hang2" className={`${isMobile ? "my-12" : "my-20"} flex flex-col gap-4`}>
            <div className={`font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
              2.프로젝트 정보</div>
            <textarea className={`flex ${isMobile ? "h-[160px]" : "h-[200px]"} border border-zinc-300 p-3 rounded-md`}
              placeholder="프로젝트 설명을 입력해주세요."
              value={projDesc} // 입력 필드의 값은 상태 변수와 바인딩됩니다.
              onChange={(e) => setProjDesc(e.target.value)} // 입력 필드 값이 변경될 때 핸들러 호출
            />
          </div>
          <div id="hang3" className={`${isMobile ? "my-12" : "my-20"} flex flex-col gap-4`}>
            <div className={`font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
              첨부자료(선택)</div>
            <div className="custom-file-input">
              <div
                onDrop={(e) => {
                  e.preventDefault();
                  const file = e.dataTransfer.files[0];
                  if (file && file.type === 'application/pdf') {
                    setSelectedFile(file);
                  } else {
                    setSelectedFile(null);
                  }
                }}
                onDragOver={(e) => e.preventDefault()}
                style={{
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: '2px dashed #ccc',
                  borderRadius: "4px",
                  marginTop: '20px',
                  color: '#aaa',
                  fontSize: '15px'
                }}
              >
                <input
                  className="input-file"
                  type="file"
                  accept=".pdf"
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                />
                {
                  selectedFile ? '선택된 파일 : ' + selectedFile.name :
                    isTablet ? "선택한 파일이 없습니다." : '클릭 또는 여기에 파일을 드랍'
                }
              </div>
            </div>
          </div>
          <div id="hang4" className={`${isMobile ? "my-12" : "my-20"} flex flex-col gap-4`}>
            <div className={`font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
              3.인적사항</div>
            <div className={`${isMobile ? "" : "grid grid-cols-2 gap-4"}`}>
              <div className="flex flex-col">
                <div className={`font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                  성명</div>
                <input className="rounded-md border border-zinc-300 p-4 my-[10px] flex"
                  placeholder="성명을 입력해 주세요"
                  value={name}
                  onChange={(e) => setName(e.target.value)} />
              </div>
              <div className="flex flex-col">
                <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
                  <div className={`font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                    회사명</div>
                  <div className="text-xs">(선택)</div></div>
                <input className="rounded-md border border-zinc-300 p-4 my-[10px] flex"
                  placeholder="회사명을 입력해 주세요"
                  value={compName}
                  onChange={(e) => setCompName(e.target.value)} />
              </div>
              <div className="flex flex-col">
                <div className={`font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                  연락처</div>
                <input className="rounded-md border border-zinc-300 p-4 my-[10px] flex"
                  placeholder="연락처를 입력해 주세요"
                  value={num}
                  onChange={(e) => setNum(e.target.value)} />
              </div>
              <div className="flex flex-col">
                <div className="flex gap-[3px] items-center">
                  <div className={`font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                    직책</div><div className="text-xs">(선택)</div></div>
                <input className="rounded-md border border-zinc-300 p-4 my-[10px] flex"
                  placeholder="직책을 입력해 주세요"
                  value={pow}
                  onChange={(e) => setPow(e.target.value)} />
              </div>
              <div className="flex flex-col">
                <div className={`font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                  이메일</div>
                <input className="rounded-md border border-zinc-300 p-4 my-[10px] flex"
                  placeholder="이메일을 입력해 주세요"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} />
              </div>
            </div>
          </div>
          <div id="hang5" className={`${isMobile ? "mt-8" : "mt-12"}`}>
            <div className="flex gap-[3px] items-center">
              <input className="appearance-none mr-2 border border-gray-300 rounded w-6 h-6"
                style={{
                  backgroundImage: agree ? `url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e")` : "",
                  backgroundSize: "100% 100%",
                  backgroundPosition: "50%",
                  backgroundRepeat: "no-repeat"
                }}
                type="checkbox"
                checked={agree}
                onChange={() => { if (agree) { setAgree(0) } else { setAgree(1); } }}
              />
              <div className={`font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                개인정보 수집 동의</div>
            </div>
          </div>
          <div id="hang6" className={`${isMobile ? "mt-8" : "mt-12"} flex flex-col gap-4`}>
            <div />
            <div id="submitBtn" className="mb-28 mt-[10px] border border-zinc-300 rounded-md text-center font-semibold py-[15px] px-5 cursor-pointer" onClick={async () => {
              if (
                projDesc.length > 0 &&
                name.length > 0 &&
                num.length > 0 &&
                email.length > 0 &&
                agree) {
                const response = await sendMail(activeTab, projDesc,
                  {
                    name: name,
                    compName: compName,
                    num: num,
                    pow: pow,
                    email: email
                  },
                  selectedFile.name,
                  selectedFile)
                console.log(response)
                refreshAll()
                navigate("/")
                alert("제출되었습니다. 감사합니다.")
              } else {
                if (activeTab.length === 0) {
                  alert("의뢰 항목을 선택해주세요.")
                } else if (projDesc.length === 0) {
                  alert("프로젝트 정보를 입력해주세요.")
                } else if (name.length === 0) {
                  alert("성명을 입력해주세요.")
                } else if (num.length === 0) {
                  alert("연락처를 입력해주세요.")
                } else if (email.length === 0) {
                  alert("이메일을 입력해주세요.")
                } else if (!agree) {
                  alert("개인정보 수집 동의가 필요합니다.")
                }
              }
            }}>의뢰하기</div>
          </div>
        </div>
        <div id="footer" className="bg-[#161616]">
          <div className="w-[70vw] py-12 mx-auto">
            <SvgHome height={isTablet ? "16px" : "24px"} />
            <div className="pt-5 pb-10 mb-5">
              <div className="text-sm text-white text-opacity-40">뉴로플로우 | 대표: 이종민 | 사업자등록번호: 773-88-02706 </div>
              <div className="text-sm text-white text-opacity-40">주소 : 경기 성남시 분당구 성남대로331번길 8, 킨스타워 19층 | 이메일 : contact@neuroflow.co.kr </div>
            </div>
            <div className="flex gap-1 items-center text-sm text-white text-opacity-40"><FaRegCopyright /> neuroflow INC. All rights reserved.</div>
          </div>
        </div>
      </div>
    </>
  )
}
