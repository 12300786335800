import { useEffect, useState } from "react";
import Nlp from "../solution/Nlp";
import Cv from "../solution/Cv";
import Footer from "../components/Footer";
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useLocation } from "react-router-dom";

export default function Solution_cv() {
  const isTablet = useMediaQuery({ maxWidth: 1349 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();

  return (
    <>
      <div className="w-screen mt-[100px]">
        <div className={`${isMobile ? "w-4/5 pt-10 pb-5" : "w-3/5 pt-20 pb-5"} mx-auto`}>
          <div className={`mb-4 font-extrabold ${isMobile ? "text-main-banner-mobile" : "text-main-banner-desktop"}`}>Solution</div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold`}>
            <p>더 좋은 사회를 위한 솔루션,</p>
            <p>뉴로플로우를 통해 만나보세요</p>
          </div>
          <div className={`flex gap-10 mt-12`}>
            <div id="tab_nlp" className={`flex relative ${isTablet ? "text-desc-tablet" : "text-desc-desktop"} py-[10px] cursor-pointer w-28 font-semibold justify-center`} onClick={() => navigate("/solution-nlp")}>
              자연어 처리
              <div id="dot_nlp" className={`absolute -bottom-[3px] left-[50%] w-[6px] h-[6px] rounded-full`} style={{ transform: "translateX(-50%)" }} />
            </div>
            <div id="tab_cv" className={`flex relative ${isTablet ? "text-desc-tablet" : "text-desc-desktop"} py-[10px] cursor-pointer w-28 font-semibold justify-center text-primary-purple`}>컴퓨터 비전
              <div id="dot_cv" className={`absolute -bottom-[3px] left-[50%] w-[6px] h-[6px] rounded-full bg-primary-purple`} style={{ transform: "translateX(-50%)" }} />
            </div>
          </div>
        </div>
        <Cv />
      </div>
      <Footer />
    </>
  )
}