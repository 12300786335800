import { ReactComponent as SvgHome } from "../assets/00_logo/neuroflow_logo_W.svg"
import { FaRegCopyright } from "react-icons/fa6";
import { MdKeyboardArrowRight } from "react-icons/md";
import contacImg from "../assets/01_home/05_Contactus.jpg"
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

export default function Footer() {
  const navigate = useNavigate()
  const isTablet = useMediaQuery({ maxWidth: 1349 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div className="w-full">
      <div className="flex gap-[30px] w-screen mx-auto bg-center" style={{ backgroundImage: `url(${contacImg})` }}>
        <div className="w-[60vw] py-28 m-auto">
          <div className={`font-semibold text-white mb-4 ${isTablet ? (isMobile ? "text-lg-title-mobile" : "text-lg-title-tablet") : " text-lg-title-desktop"} `}>
            <p>비즈니스 성공을 위한 필수 솔루션,</p>
            <p>뉴로플로우와 함께 하세요</p>
          </div>
          <div className={`flex border-[1.6px] border-white border-opacity-40 rounded-xl ${isMobile ? "text-sm w-36" : "text-lg w-40 h-10"} pl-4 py-2 items-center text-white text-opacity-70 font-semibold cursor-pointer`} onClick={() => navigate("/contact")}>
            CONTACT US <MdKeyboardArrowRight size={24} style={{ marginLeft: "4px" }} />
          </div>
        </div>
      </div>
      <div className="bg-[#161616]">
        <div className="w-[70vw] py-12 mx-auto">
          <SvgHome height={isTablet ? "16px" : "24px"} />
          <div className="pt-5 pb-10 mb-5">
            <div className="text-[13px] text-white text-opacity-40">
              <p >뉴로플로우 | 대표: 이종민 | 사업자등록번호: 773-88-02706 </p>
              <p >주소 : 경기 성남시 분당구 성남대로331번길 8, 킨스타워 19층 | 이메일 : contact@neuroflow.co.kr </p>
            </div>
          </div>
          <div className="flex gap-1 items-center text-[13px] text-white text-opacity-40"><FaRegCopyright size={12} /> neuroflow INC. All rights reserved.</div>
        </div>
      </div>
    </div>
  )
}