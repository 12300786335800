import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Works from './pages/Works';
import Solution from './pages/Solution';
import Solution_cv from "./pages/Solution_cv";

import Contact from './pages/Contact';
import { useState, useEffect } from 'react';
import Headerbar from './components/Header';
import { useRef } from 'react';
import MobileMenu from './components/MobileMenu';
import { useMediaQuery } from 'react-responsive';
function App() {
  const [visible, setVisible] = useState(1)
  const isTablet = useMediaQuery({ maxWidth: 1349 });

  const [menuVisible, setMenuVisible] = useState(0)

  const [curHeader, setCurHeader] = useState("None")
  // scroll이 일어나는 container. 현재 scroll 내린 만큼의 값을 구하기 위해
  const scrollContainerRef = useRef(null)
  // 과거의 scroll 값 저장 위해서
  const scrollRef = useRef(0)
  // 현재 scrollTop 지정

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollRef.current = scrollContainerRef.current.scrollTop;
    }
  }, [scrollContainerRef.current]);

  const handleScroll = (e) => {
    const prevScroll = scrollRef.current;
    // scrollTop : distance from the element's top to its topmost visible content
    const currentScroll = e.target.scrollTop;
    if (currentScroll > 655) {
      setCurHeader("white")
    } else {
      setCurHeader("None")
    }
    if (prevScroll > currentScroll) {
      setVisible(1)
    } else {
      setVisible(0)
    }
    // 현재 값을 넣어서 다음 scroll handle에서 이전 값으로 쓰게
    scrollRef.current = currentScroll;
  }

  const { pathname } = useLocation(); // 현재 경로 가져오기
  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: 0 }); // 페이지 로드시 스크롤을 맨 위로 이동
    }
  }, [pathname]); // 경로가 변경될 때마다 실행

  return (
    <div className="App"  >
      {/* <Router> */}
      {/* <ScrollToTop  /> */}
      {isTablet ?
        <MobileMenu menuVisible={menuVisible} setMenuVisible={setMenuVisible} curHeader={curHeader} /> :
        <Headerbar visible={visible} curHeader={curHeader} />
      }
      <div onScroll={handleScroll} ref={scrollContainerRef}
        style={{ position: "absolute", overflowY: 'scroll', overflowX: "hidden", height: "100vh" }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/works" element={<Works />} />
          <Route path="/about" element={<About />} />
          <Route path="/solution-nlp" element={<Solution />} />
          <Route path="/solution-cv" element={<Solution_cv />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/solution" element={<Navigate replace to="/solution-nlp" />} />

        </Routes>
      </div>

      {/* </Router> */}


    </div >
  );
}

export default App;
