import Whoweare from "../about/Whoweare";
import History from "../about/History";
import Footer from "../components/Footer";
import { useMediaQuery } from 'react-responsive';

export default function About() {
  const isTablet = useMediaQuery({ maxWidth: 1349 });
  const isMobile = useMediaQuery({ maxWidth: 767 });



  return (
    <>
      <div className="w-screen mt-[100px]">
        <div className={`${isMobile ? "w-4/5 py-10" : "w-3/5 py-20"} gap-2 mx-auto  mt-10`}>
          <div className={`mb-4 font-extrabold ${isMobile ? "text-main-banner-mobile" : "text-main-banner-desktop"}`}>
            About</div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold`}>
            <p>
              사회 친화적 딥테크 기업, </p><p>
              뉴로플로우를 소개합니다
            </p>
          </div>
        </div>
        <Whoweare />
        <History />
      </div>
      <Footer />
    </>
  )
}