import React, { useState } from 'react';
import mainBg from "../assets/01_home/01_home_background.jpg"
import mainBgMobile from "../assets/01_home/01_home_background_mobile.png"
import AboutPart from "../home/AboutPart";
import Partners from "../home/Partners";
import Process from "../home/Process";
import Footer from "../components/Footer";
import { useMediaQuery } from 'react-responsive';

export default function Home() {
  const isTablet = useMediaQuery({ maxWidth: 1349 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  return (
    <>
      <div className="w-screen ">
        <div className="relative w-full bg-cover h-screen flex justify-center" style={{ backgroundImage: `url(${isTablet ? mainBgMobile : mainBg})` }}>
          <div className={`text-white absolute ${isMobile ? "top-56" : "top-72"} flex flex-col w-3/4`}>
            <div className={`mb-4 font-semibold ${isMobile ? "text-main-banner-mobile" : "text-main-banner-desktop"}`}>
              <p>
                검증된 AI솔루션으로
                {/* Neuroflow의 기술력이 */}
              </p>
              <p>
                {/* 새로운 미래를 엽니다 */}
                새로운 비전을 제시합니다
              </p>
            </div>
            <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"}`}>
              뉴로플로우와 함께 비즈니스에 가치를 더하는 AI 솔루션을 도입해보세요
              {/* 세상의 다양한 아이디어들을 현실화 시키는것, 뉴로플로우만의 기술력 및 목표입니다 */}
            </div>
          </div>
        </div>
        <AboutPart />
        <Partners />
        <Process />
      </div>
      <Footer />
    </>
  )
}