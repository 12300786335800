import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { ReactComponent as SvgHome } from "../assets/00_logo/neuroflow_logo_W.svg"
import { ReactComponent as Svg } from "../assets/00_logo/neuroflow_logo.svg"
import { RxHamburgerMenu } from "react-icons/rx";
import { GiHamburgerMenu } from "react-icons/gi"; // 더 도톰
export default function MobileMenu({ curHeader, menuVisible, setMenuVisible }) {
  const location = useLocation();
  const { pathname } = location;
  return (
    <>
      <div className={`absolute flex w-full justify-center z-40 `}>
        <div className={`absolute top-9 z-50 w-[90%] mx-auto flex justify-between items-center`}>
          <Link to="/" style={{ textDecoration: "none", color: "black" }} onClick={() => setMenuVisible(0)}>
            {pathname === "/" && curHeader === "None" && !menuVisible ? <SvgHome height="14px" /> : <Svg height="14px" />}
          </Link>
          <RxHamburgerMenu className={`${pathname === "/" && curHeader === "None" && !menuVisible ? "text-white" : "text-primary-gray"}`} size={22} onClick={() => { if (menuVisible) { setMenuVisible(0) } else { setMenuVisible(1) } }} />
        </div>
        <div className={`flex w-screen h-[80px] fixed ${pathname === "/" && curHeader === "None" ? "bg-transparent shadow-neutral-600 shadow-sm" : "bg-white shadow-md"}`}>
          <div className={`fixed top-0  ${menuVisible ? 'left-0 transition-transform duration-500 ' : 'translate-x-full transition-transform duration-500'} flex w-full justify-center z-40 `}>
            <div className={`flex justify-end  w-screen h-screen bg-white fixed `}>
              <div className="mt-[30px] flex mr-5 items-center">
                <div className="flex flex-col gap-10">
                  <Link to="/about" style={{ textDecoration: "none", color: "black" }} onClick={() => setMenuVisible(0)} >
                    <div className={`flex items-end justify-end  h-[30px] font-bold text-primary-gray text-lg-title-mobile`}>ABOUT
                    </div>
                  </Link>
                  <Link to="/solution" style={{ textDecoration: "none", color: "black" }} onClick={() => setMenuVisible(0)} >
                    <div className={` flex items-end justify-end  h-[30px] font-bold text-primary-gray  text-lg-title-mobile`}>SOLUTION
                    </div>
                  </Link>
                  <Link to="/solution-nlp" style={{ textDecoration: "none", color: "black" }} onClick={() => setMenuVisible(0)} >
                    <div className={`flex items-end justify-end  h-[30px] text-primary-gray text-desc-tablet`}>자연어처리
                    </div>
                  </Link>
                  <Link to="/solution-cv" style={{ textDecoration: "none", color: "black" }} onClick={() => setMenuVisible(0)} >
                    <div className={`flex items-end justify-end  h-[30px]  text-primary-gray text-desc-tablet`}>컴퓨터비전
                    </div>
                  </Link>
                  <Link to="/contact" style={{ textDecoration: "none", color: "black" }} onClick={() => setMenuVisible(0)} >
                    <div className={`flex items-end justify-end  h-[30px] font-bold text-primary-gray text-lg-title-mobile`}>CONTACT
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}