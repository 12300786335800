import processImg from "../assets/01_home/04_process_image.jpg"
import { ReactComponent as Step1 } from "../assets/01_home/04_process_step1.svg"
import { ReactComponent as Step2 } from "../assets/01_home/04_process_step2.svg"
import { ReactComponent as Step3 } from "../assets/01_home/04_process_step3.svg"
import { ReactComponent as Step4 } from "../assets/01_home/04_process_step4.svg"
import { useMediaQuery } from 'react-responsive';

export default function Process() {
  const isTablet = useMediaQuery({ maxWidth: 1349 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      <div className=" pt-28 pb-20">
        <div className={`${isMobile ? "w-4/5" : "w-3/5"} mx-auto`}>
          <div className={`text-primary-purple ${isMobile ? " text-xs" : ""} font-extrabold mb-2`}>Process</div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold`}> 개발 프로세스는 이렇게 진행됩니다</div>
          <div id="boxWrap" className={`mt-16 mb-12 grid ${isTablet ? (isMobile ? "grid-cols-1 grid-rows-4 gap-8" : "grid-cols-2 grid-rows-2 gap-8") : "grid-cols-4 grid-rows-1 gap-4"}`}>
            <div className="relative shadow-md bg-bg-gray border pt-10 mb-2 rounded-xl">
              <div className={`absolute left-1/2 -translate-x-1/2 rounded-full ${isMobile ? "w-8 h-8 -top-4 text-lg-title-mobile" : "w-12 h-12 -top-6 text-lg-title-desktop"} bg-primary-purple text-white justify-center flex items-center`}>1</div>
              <div id="icon-wrap1" className="flex justify-center my-4"> <Step1 height={`${isMobile ? "64px" : "90px"}`} /> </div>
              <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3 text-center p-2`}>
                <p>기능 정의 및</p>
                <p>개발 일정 조율</p>
              </div>
            </div>
            <div className="relative shadow-md bg-bg-gray border pt-10 mb-2 rounded-xl">
              <div className={`absolute left-1/2 -translate-x-1/2 rounded-full ${isMobile ? "w-8 h-8 -top-4 text-lg-title-mobile" : "w-12 h-12 -top-6 text-lg-title-desktop"} bg-primary-purple text-white justify-center flex items-center`}>2</div>
              <div id="icon-wrap2" className="flex justify-center my-4"> <Step2 height={`${isMobile ? "64px" : "90px"}`} /> </div>
              <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3 text-center p-2`}>
                <p>기술 개발</p>
              </div>
            </div>
            <div className="relative shadow-md bg-bg-gray border pt-10 mb-2 rounded-xl">
              <div className={`absolute left-1/2 -translate-x-1/2 rounded-full ${isMobile ? "w-8 h-8 -top-4 text-lg-title-mobile" : "w-12 h-12 -top-6 text-lg-title-desktop"} bg-primary-purple text-white justify-center flex items-center`}>3</div>
              <div id="icon-wrap3" className="flex justify-center my-4"> <Step3 height={`${isMobile ? "64px" : "90px"}`} /> </div>
              <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3 text-center p-2`}>
                <p>솔루션 납품</p>
              </div>
            </div>
            <div className="relative shadow-md  bg-bg-gray border pt-10 rounded-xl">
              <div className={`absolute left-1/2 -translate-x-1/2 rounded-full ${isMobile ? "w-8 h-8 -top-4 text-lg-title-mobile" : "w-12 h-12 -top-6 text-lg-title-desktop"} bg-primary-purple text-white justify-center flex items-center`}>4</div>
              <div id="icon-wrap4" className="flex justify-center my-4"> <Step4 height={`${isMobile ? "64px" : "90px"}`} /> </div>
              <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3 text-center p-2`}>
                <p>유지 보수</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}