import whoImg from "../assets/02_about/01_who_we_are_bg.jpg"
import whoImgMobile from "../assets/02_about/00_about_mobile.png"
import { useMediaQuery } from 'react-responsive';

export default function Whoweare() {

  const isTablet = useMediaQuery({ maxWidth: 1349 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      <div className={`${isMobile ? "py-20" : " py-80"} bg-cover bg-no-repeat`} style={{ backgroundImage: `url(${isTablet ? whoImgMobile : whoImg})`, backgroundPosition: "0px 0px" }}>
        <div className={`${isMobile ? "w-4/5" : "w-3/5"} mx-auto`}>
          <div className={`text-primary-purple ${isMobile ? " text-xs" : ""} font-extrabold mb-2 mt-5`}>Who we are</div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"}  text-white font-semibold`}>
            <p> 당신의 작은 아이디어,</p>
            {/* 세상의 다양한 아이디어들을 현실화 시키는 것,  */}
            <p>세상을 바꿀 수도 있습니다 </p>
          </div>
          <div className="my-5">
            <div className={`text-white ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
              {!isTablet &&
                <>
                  <p>지금 이순간에도 끊임없이 쏟아져 나오는 세상을 위한 창의적이고 다양한 아이디어들,</p>
                  <p>대다수가 가공되지 못한 채 기술력과 솔루션 방향성에 대한 부재로 인하여 사라집니다</p>
                  <p>뉴로플로우는 이들의 길잡이 및 솔루션 제공자의 역할을 지향합니다</p>
                </>
              }
              {isMobile ? <>
                <p>AI 도입을 위한 지름길로 안내합니다</p>
                <p>단순히 어떤 AI 기술을 도입하면 좋을지부터 </p>
                <p>기술력의 부재로 막힌 솔루션까지 모두.</p>
                <p>세상을 위한 모든 아이디어를 환영합니다 </p>
              </> :
                <>
                  <p>우리는 당신을 AI 도입을 위한 지름길로 안내합니다</p>
                  <p>단순히 어떤 AI 기술을 도입하면 좋을까 싶은 물음부터 </p>
                  <p>기술력의 부재로 막힌 솔루션까지 모두 해결해드립니다</p>
                  <p>세상을 이롭게 하는 모든 아이디어를 환영합니다 </p>
                </>}

            </div>
          </div>
        </div>
      </div>
    </>
  )
}