import partnerImg from "../assets/01_home/03_partner_bg.jpg"
import { ReactComponent as Partner1 } from "../assets/01_home/03_partner_wateratom.svg"
import { ReactComponent as Partner2 } from "../assets/01_home/03_partner_cheoldo.svg"
import { ReactComponent as Partner3 } from "../assets/01_home/03_partner_seoul.svg"
import { ReactComponent as Partner4 } from "../assets/01_home/03_partner_consumer.svg"
import { ReactComponent as Partner5 } from "../assets/01_home/03_partner_skku.svg"
import Partner6 from "../assets/01_home/03_partner_line.png"
import { useMediaQuery } from 'react-responsive';

export default function Partners() {
  const isTablet = useMediaQuery({ maxWidth: 1349 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div className={`${isMobile ? "py-20" : " py-24"} w-screen bg-no-repeat bg-center bg-cover`} style={{ backgroundImage: `url(${partnerImg})` }} >
      <div className={`${isMobile ? "w-4/5" : "w-3/5"} gap-2 mx-auto`}>
        <div className={`text-primary-purple ${isMobile ? " text-xs" : ""} font-extrabold mb-2 mt-5 text-center`}>Partners</div>
        <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"}  text-white font-semibold text-center`}>
          <p>다양한 분야의 기업이 <br />뉴로플로우와 함께하고 있습니다</p>
        </div>
        <div id="boxWrap" className={`my-12  gap-6 grid ${isTablet ? (isMobile ? "grid-cols-1 grid-rows-6" : "grid-cols-2 grid-rows-3") : "grid-cols-3 grid-rows-2"}`}>
          <div className="bg-white bg-opacity-20 rounded-md py-4 flex justify-center items-center px-4">
            <Partner1 height={`${isTablet ? "28px" : "44px"}`} />
          </div>
          <div className="bg-white bg-opacity-20 rounded-md  py-8 flex justify-center items-center px-4">
            <Partner2 height={`${isTablet ? "28px" : "44px"}`} />
          </div>
          <div className={`bg-white bg-opacity-20 rounded-md py-8 flex justify-center items-center px-4`}>
            <Partner3 height={`${isTablet ? "32px" : "44px"}`} style={{ transform: "translateX(-3%)" }} />
          </div>
          <div className="bg-white bg-opacity-20 rounded-md py-8 flex justify-center items-center px-4">
            <Partner4 height={`${isTablet ? "42px" : "60px"}`} style={{ transform: "translateX(-3%)" }} />
          </div>
          <div className="bg-white bg-opacity-20 rounded-md py-8 flex justify-center items-center px-4">
            <Partner5 height={`${isTablet ? "32px" : "44px"}`} />
          </div>
          <div className="bg-white bg-opacity-20 rounded-md py-8 flex justify-center items-center px-4">
            <img src={Partner6} style={{ transform: "translateX(-3%)", height: `${isTablet ? "28px" : "38px"}` }} />
          </div>
        </div>
      </div>
    </div>
  )
}
