import { Link } from 'react-router-dom';
import { ReactComponent as SvgHome } from "../assets/00_logo/neuroflow_logo_W.svg"
import { ReactComponent as Svg } from "../assets/00_logo/neuroflow_logo.svg"
import { useLocation } from "react-router-dom";
import { useState } from 'react';

export default function Headerbar({ visible, curHeader }) {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className={`absolute ${visible ? 'top-0 transition-transform duration-500' : '-translate-y-[85px] transition-transform duration-500'} flex w-full justify-center z-50 `}>
      <div className={`flex w-screen h-[80px] fixed ${pathname === "/" && curHeader === "None" ? "bg-transparent shadow-neutral-600 shadow-sm" : "bg-white shadow-md"}`}>
        <div className={`w-4/5 mx-auto mt-[10px] flex justify-between items-center`}>
          {/* // 데스크탑용 상단바 */}
          <div id="logo" className={`flex`}>
            <Link to="/" style={{ textDecoration: "none", color: "black" }}>
              {pathname === "/" && curHeader === "None" ? <SvgHome height="24px" /> : <Svg height="24px" />}
            </Link>
          </div>
          <div className=" inline-flex mr-20 gap-20">
            <Link to="/about" style={{ textDecoration: "none", color: "black" }}>
              <div className={`relative flex items-end h-[30px] font-semibold ${pathname === "/" && curHeader === "None" ? "text-white" : pathname === "/about" ? "text-primary-purple" : "text-primary-gray"}`}>ABOUT
                <div className={`absolute z-90 -bottom-[14px] left-1/2 -translate-x-1/2 rounded-full w-1.5 h-1.5 ${pathname === "/about" ? "bg-primary-purple" : ""}`} />
              </div>
            </Link>
            <Link to="/solution" style={{ textDecoration: "none", color: "black" }}>
              <div className={`relative flex items-end h-[30px] font-semibold ${pathname === "/" && curHeader === "None" ? "text-white" : pathname === "/solution" ? "text-primary-purple" : "text-primary-gray"}`}>SOLUTION
                <div className={`absolute z-90 -bottom-[14px] left-1/2 -translate-x-1/2 rounded-full w-1.5 h-1.5 ${pathname === "/solution" ? "bg-primary-purple" : ""}`} />
              </div>
            </Link>
            <Link to="/contact" style={{ textDecoration: "none", color: "black" }}>
              <div className={`relative flex items-end h-[30px] font-semibold ${pathname === "/" && curHeader === "None" ? "text-white" : pathname === "/contact" ? "text-primary-purple" : "text-primary-gray"}`}>CONTACT
                <div className={`absolute z-90 -bottom-[14px] left-1/2 -translate-x-1/2 rounded-full w-1.5 h-1.5 ${pathname === "/contact" ? "bg-primary-purple" : ""}`} />
              </div>
            </Link>
          </div>
        </div>

      </div>
    </div>
  )
}