
export const sendMail = async (activeTab, projDesc, info, fileName, fileBody) => {
  const fileUrl = "/nodejs";
  try {
    const formData = new FormData();
    formData.append('fileName', fileName);
    formData.append('fileBody', fileBody);
    formData.append('mailText', JSON.stringify({
      activeTab: activeTab,
      projDesc: projDesc,
      name: info.name,
      compName: info.compName,
      num: info.num,
      pow: info.pow,
      email: info.email,
    }))

    const response2 = await fetch(fileUrl, {
      method: "POST",
      body: formData
    });
    const responseData = await response2.json();
    console.log("responseData:", responseData)
  } catch (error) {
    console.error("Error posting email:", error);
    throw error;
  }
};
